import axios from 'axios';
import { API_GATEWAY } from './rotasServidor';

//.....................Início: Mensagens.............................

export const getListaDeParaMensagem = async function(empresa,de,para,limite,pagina) {
    let query = '';

    if (empresa !== undefined) {
        query = `/?empresa=${empresa}`;
    }

    if (de !== '') {
        query = `${query}&de=${de}`;
    }

    if (para !== '') {
        query = `${query}&para=${para}`;
    }

    if (limite !== '') {
        query = `${query}&limite=${limite}`
    } else {
        query = `${query}&limite=10`
    }

    if (pagina !== '') {
        query = `${query}&pagina=${pagina}`
    } else {
        query = `${query}&pagina=1`
    }

    return axios.get(`${API_GATEWAY}/depara/mensagem${query}`);
}

export const addDeParaMensagem = async function(body) {
    return axios.post(`${API_GATEWAY}/depara/mensagem`, body);
}

export const updDeParaMensagem = async function(body) {
    return axios.put(`${API_GATEWAY}/depara/mensagem`, body);
}

export const delDeParaMensagem = async function(id) {
    let query = '';

    if (id !== undefined) {
        query = `/?id=${id}`;
    }

    return axios.delete(`${API_GATEWAY}/depara/mensagem${query}`);
}
//........................Fim: Mensagens.............................

//.....................Início: Contas Clientes.......................

export const getListaDeParaContaContabil = async function(filtro) {
    let query = '';

    if (filtro.idCliente !== undefined) {
        query = `/?idCliente=${filtro.idCliente}`;
    }

    if (filtro.codEmpSapiens !== '' && filtro.codEmpSapiens !== undefined) {
        query = `${query}&codEmpSapiens=${filtro.codEmpSapiens}`;
    }

    if (filtro.conta !== '' && filtro.conta !== undefined) {
        query = `${query}&conta=${filtro.conta}`;
    }

    if (filtro.classificacao !== '' && filtro.classificacao !== undefined) {
        query = `${query}&classificacao=${filtro.classificacao}`;
    }

    if (filtro.paralela !== '' && filtro.paralela !== undefined) {
        query = `${query}&paralela=${filtro.paralela}`;
    }

    if (filtro.nomenclatura !== '' && filtro.nomenclatura !== undefined) {
        query = `${query}&nomenclatura=${filtro.nomenclatura}`;
    }

    if (filtro.limite !== '') {
        query = `${query}&limite=${filtro.limite}`
    } else {
        query = `${query}&limite=0`
    }

    if (filtro.pagina !== '') {
        query = `${query}&pagina=${filtro.pagina}`
    } else {
        query = `${query}&pagina=0`
    }

    return axios.get(`${API_GATEWAY}/depara/contacontabil${query}`);
}

export const addDeParaContaContabil = async function(body) {
    return axios.post(`${API_GATEWAY}/depara/contacontabil`, body);
}

export const updDeParaContaContabil = async function(body) {
    return axios.put(`${API_GATEWAY}/depara/contacontabil`, body);
}

export const delDeParaContaContabil = async function(id) {
    let query = '';

    if (id !== undefined) {
        query = `/?id=${id}`;
    }

    return axios.delete(`${API_GATEWAY}/depara/contacontabil${query}`);
}
//........................Fim: Contas Clientes...........................

//.....................Início: Item Sped.......................

export const getListaDeParaItemSped = async function(filtro) {
    let query = '/';
    let simbolo = "?";


    if (filtro.idCliente !== '' && filtro.idCliente !== undefined) {
        query = `${query}${simbolo}idCliente=${filtro.idCliente}`;
        simbolo = "&";
    }

    if (filtro.codfam !== '' && filtro.codfam !== undefined) {
        query = `${query}${simbolo}codfam=${filtro.codfam}`;
        simbolo = "&";
    }

    if (filtro.codori !== '' && filtro.codori !== undefined) {
        query = `${query}${simbolo}codori=${filtro.codori}`;
        simbolo = "&";
    }

    if (filtro.tippro !== '' && filtro.tippro !== undefined) {
        query = `${query}${simbolo}tippro=${filtro.tippro}`;
        simbolo = "&";
    }

    if (filtro.limite !== '') {
        query = `${query}${simbolo}limite=${filtro.limite}`
        simbolo = "&";
    } else {
        query = `${query}${simbolo}limite=0`
        simbolo = "&";
    }

    if (filtro.pagina !== '') {
        query = `${query}${simbolo}pagina=${filtro.pagina}`
        simbolo = "&";
    } else {
        query = `${query}${simbolo}pagina=0`
        simbolo = "&";
    }

    return axios.get(`${API_GATEWAY}/depara/itemsped${query}`);
}

export const addDeParaItemSped = async function(body) {
    return axios.post(`${API_GATEWAY}/depara/itemsped`, body);
}

export const updDeParaItemSped = async function(body) {
    return axios.put(`${API_GATEWAY}/depara/itemsped`, body);
}

export const delDeParaItemSped = async function(id) {
    let query = '';

    if (id !== undefined) {
        query = `/?id=${id}`;
    }

    return axios.delete(`${API_GATEWAY}/depara/itemsped${query}`);
}
//........................Fim: Item Sped...........................

//.....................Início: Produto.......................

export const getListaDeParaProduto = async function(filtro) {
    let query = '/';
    let simbolo = "?";


    if (filtro.idCliente !== '' && filtro.idCliente !== undefined) {
        query = `${query}${simbolo}idCliente=${filtro.idCliente}`;
        simbolo = "&";
    }

    if (filtro.codCliente !== '' && filtro.codCliente !== undefined) {
        query = `${query}${simbolo}codCliente=${filtro.codCliente}`;
        simbolo = "&";
    }

    if (filtro.codCDI !== '' && filtro.codCDI !== undefined) {
        query = `${query}${simbolo}codCDI=${filtro.codCDI}`;
        simbolo = "&";
    }

    if (filtro.tipo !== '' && filtro.tipo !== undefined) {
        query = `${query}${simbolo}tipo=${filtro.tipo}`;
        simbolo = "&";
    }

    if (filtro.limite !== '') {
        query = `${query}${simbolo}limite=${filtro.limite}`
        simbolo = "&";
    } else {
        query = `${query}${simbolo}limite=0`
        simbolo = "&";
    }

    if (filtro.pagina !== '') {
        query = `${query}${simbolo}pagina=${filtro.pagina}`
        simbolo = "&";
    } else {
        query = `${query}${simbolo}pagina=0`
        simbolo = "&";
    }

    return axios.get(`${API_GATEWAY}/depara/produto${query}`);
}

export const addDeParaProduto = async function(body) {
    return axios.post(`${API_GATEWAY}/depara/produto`, body);
}

export const updDeParaProduto = async function(body) {
    return axios.put(`${API_GATEWAY}/depara/produto`, body);
}

export const delDeParaProduto = async function(id) {
    let query = '';

    if (id !== undefined) {
        query = `/?id=${id}`;
    }

    return axios.delete(`${API_GATEWAY}/depara/produto${query}`);
}
//........................Fim: Produto...........................

//.....................Início: NCM.......................

export const getListaDeParaNcm = async function(filtro) {
    let query = '/';
    let simbolo = "?";


    if (filtro.idCliente != '' && filtro.idCliente !== undefined) {
        query = `${query}${simbolo}idCliente=${filtro.idCliente}`;
        simbolo = "&";
    }

    if (filtro.codSenior !== '' && filtro.codSenior !== undefined) {
        query = `${query}${simbolo}codSenior=${filtro.codSenior}`;
        simbolo = "&";
    }

    if (filtro.descricao !== '' && filtro.descricao !== undefined) {
        query = `${query}${simbolo}descricao=${filtro.descricao}`;
        simbolo = "&";
    }

    if (filtro.codGoverno !== '' && filtro.codGoverno !== undefined) {
        query = `${query}${simbolo}codGoverno=${filtro.codGoverno}`;
        simbolo = "&";
    }

    if (filtro.limite !== '') {
        query = `${query}${simbolo}limite=${filtro.limite}`
        simbolo = "&";
    } else {
        query = `${query}${simbolo}limite=0`
        simbolo = "&";
    }

    if (filtro.pagina !== '') {
        query = `${query}${simbolo}pagina=${filtro.pagina}`
        simbolo = "&";
    } else {
        query = `${query}${simbolo}pagina=0`
        simbolo = "&";
    }
    
    return axios.get(`${API_GATEWAY}/depara/ncm${query}`);
}

export const addDeParaNcm = async function(body) {
    return axios.post(`${API_GATEWAY}/depara/ncm`, body);
}

export const updDeParaNcm = async function(body) {
    return axios.put(`${API_GATEWAY}/depara/ncm`, body);
}

export const delDeParaNcm = async function(id) {
    let query = '';

    if (id !== undefined) {
        query = `/?id=${id}`;
    }

    return axios.delete(`${API_GATEWAY}/depara/ncm${query}`);
}
//........................Fim: NCM...........................

//.....................Início: Participante.......................

export const getListaDeParaParticipante = async function(filtro) {
    let query = '/';
    let simbolo = "?";


    if (filtro.idCliente !== '' && filtro.idCliente !== undefined) {
        query = `${query}${simbolo}idCliente=${filtro.idCliente}`;
        simbolo = "&";
    }

    if (filtro.codERP !== '' && filtro.codERP !== undefined) {
        query = `${query}${simbolo}codERP=${filtro.codERP}`;
        simbolo = "&";
    }

    if (filtro.codSapiens !== '' && filtro.codSapiens !== undefined) {
        query = `${query}${simbolo}codSapiens=${filtro.codSapiens}`;
        simbolo = "&";
    }

    if (filtro.tipo !== '' && filtro.tipo !== undefined) {
        query = `${query}${simbolo}tipo=${filtro.tipo}`;
        simbolo = "&";
    }

    if (filtro.limite !== '') {
        query = `${query}${simbolo}limite=${filtro.limite}`
        simbolo = "&";
    } else {
        query = `${query}${simbolo}limite=0`
        simbolo = "&";
    }

    if (filtro.pagina !== '') {
        query = `${query}${simbolo}pagina=${filtro.pagina}`
        simbolo = "&";
    } else {
        query = `${query}${simbolo}pagina=0`
        simbolo = "&";
    }

    return axios.get(`${API_GATEWAY}/depara/participante${query}`);
}

export const addDeParaParticipante = async function(body) {
    return axios.post(`${API_GATEWAY}/depara/participante`, body);
}

export const updDeParaParticipante = async function(body) {
    return axios.put(`${API_GATEWAY}/depara/participante`, body);
}

export const delDeParaParticipante = async function(id) {
    let query = '';

    if (id !== undefined) {
        query = `/?id=${id}`;
    }

    return axios.delete(`${API_GATEWAY}/depara/participante${query}`);
}
//........................Fim: Participante...........................

//.....................Início: Série Fiscal.......................

export const getListaDeParaSerie = async function(filtro) {
    let query = '/';
    let simbolo = "?";


    if (filtro.idCliente !== '' && filtro.idCliente !== undefined) {
        query = `${query}${simbolo}idCliente=${filtro.idCliente}`;
        simbolo = "&";
    }

    if (filtro.series !== '' && filtro.series !== undefined) {
        query = `${query}${simbolo}series=${filtro.series}`;
        simbolo = "&";
    }

    if (filtro.formulario_nota !== '' && filtro.formulario_nota !== undefined) {
        query = `${query}${simbolo}formulario_nota=${filtro.formulario_nota}`;
        simbolo = "&";
    }

    if (filtro.tipo_nota !== '' && filtro.tipo_nota !== undefined) {
        query = `${query}${simbolo}tipo_nota=${filtro.tipo_nota}`;
        simbolo = "&";
    }

    if (filtro.codSnf !== '' && filtro.codSnf !== undefined) {
        query = `${query}${simbolo}codSnf=${filtro.codSnf}`;
        simbolo = "&";
    }

    if (filtro.limite !== '') {
        query = `${query}${simbolo}limite=${filtro.limite}`
        simbolo = "&";
    } else {
        query = `${query}${simbolo}limite=0`
        simbolo = "&";
    }

    if (filtro.pagina !== '') {
        query = `${query}${simbolo}pagina=${filtro.pagina}`
        simbolo = "&";
    } else {
        query = `${query}${simbolo}pagina=0`
        simbolo = "&";
    }

    return axios.get(`${API_GATEWAY}/depara/seriefiscal${query}`);
}

export const addDeParaSerie = async function(body) {
    return axios.post(`${API_GATEWAY}/depara/seriefiscal`, body);
}

export const updDeParaSerie = async function(body) {
    return axios.put(`${API_GATEWAY}/depara/seriefiscal`, body);
}

export const delDeParaSerie = async function(id) {
    let query = '';

    if (id !== undefined) {
        query = `/?id=${id}`;
    }

    return axios.delete(`${API_GATEWAY}/depara/seriefiscal${query}`);
}
//........................Fim: Série Fiscal...........................